(function ($) {
    $(document).ready(function () {

        $('#mob-menu').click(function () {
            $('#header').addClass("menu-open");
            $('#logo').hide();
        });

        $('#closeBtn').click(function () {
            $('#header').removeClass("menu-open");
            $('#logo').show();
        });

        /* If there is no cookie set then show the intro and hide the main */
        var registerPage = $('.register-form').length > 0;
        if (registerPage) {
            var registerCookie = Cookies.get('register');
            console.log(registerCookie);
            if (registerCookie === undefined) {
                $('.register-form-intro').show();
                $('.register-form form').hide();
            }

            // Set up Autocomplete
            $('#schoolidname').autocomplete({
                source: function(request, response) {
                    $.ajax({
                        url: "/umbraco/api/schoolsdata/autocomplete?location=" + $('input[name=locationName]').val().replace("&", "%26"),
                        data: {
                            q: request.term
                        },
                        dataType: "json",
                        contentType: "application/json; charset=utf-8",
                        dataFilter: function(data) { return data; },
                        success: function(data) {
                            console.log("success");
                            response($.map(data,
                                function(item) {
                                    return {
                                        value: item.value,
                                        label: item.label
                                    };
                                }));
                        },
                        error: function(xhr, textStatus, errorThrown) {
                            alert("error: " + errorThrown);
                        }
                    });
                },
                minLength: 3,
                select: function(event, ui) {
                    event.preventDefault();
                    $("#schoolid").val(ui.item.value);
                    $("#schoolidname").val(ui.item.label);
                }
            });
        }

        $('.register-form #register-intro-button').on('click',
            function (e) {
                e.preventDefault();
                Cookies.set('register', 'true'); // Pass no expiry so this is a session cookie
                $('.register-form-intro').hide();
                $('.register-form form').show();
            });

        $('.register-form-intro span, .participants-intro span').on('click',
            function () {
                var dummy = document.createElement('input'),
                    text = window.location.href;

                document.body.appendChild(dummy);
                dummy.value = text;
                dummy.select();
                document.execCommand('copy');
                document.body.removeChild(dummy);

                // Add a popup
                if ($(".popuptext").length === 0) {
                    var newNode = document.createElement("span");
                    newNode.className = "popuptext";
                    newNode.id = "myPopup";
                    newNode.innerHTML = "Link Copied";
                    $(this).append(newNode);
                }

                // Show the popup
                var popup = document.getElementById('myPopup');
                popup.classList.toggle('show');
            });

        $('#update-tokens').on('click', function (e) {
            e.preventDefault();
            $('.update-totaliser').show();
            $('#update-tokens').hide();
        });

        $('#cancel-update-tokens').on('click', function (e) {
            e.preventDefault();
            $('.update-totaliser').hide();
            $('#update-tokens').show();
        });

        // A better way to only run this when needed
        var totaliser = $('.totaliser').length > 0;
        var totaliserProgress = parseInt($('#totaliser-progress').val());
        var totaliserGoal = parseInt($('#totaliser-goal').val());

        if (totaliser) {
            // Avoid us working out > 100%
            if (totaliserProgress > totaliserGoal) {
                totaliserProgress = totaliserGoal;
            }

            // Height of the DIV, then progress/goal as a multiplier against Div size
            var ratio = totaliserProgress / totaliserGoal;
            console.log(ratio);
            var totaliserHeight = $('.totaliser').height();
            var progress = totaliserHeight * ratio;

            $(".totaliser-progress").css('height', progress + 'px');

            // Update the balloon arrow box
            if (totaliserProgress > 0) {
                var arrowHeight = $('.totaliser-arrow').height();
                var arrowPosition = progress - (arrowHeight / 2) -15;

                $('.totaliser-arrow').css('bottom', arrowPosition + 'px');
                $('.totaliser-arrow').show();
            }
        }
    });
})(jQuery);